import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#F08300';
const footerRelatedLinks = [
	{ title: 'MJF Swipe app', url: '/en/projects/montreux-jazz-festival-swipe-app/' },
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				image_1: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange/terre_hommes_1.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange/terre_hommes_2.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange/terre_hommes_3.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/terre-des-hommes-campagne-orange/',
					lang: 'fr',
				},
			]}
			title="Terre des Hommes, My orange - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Terre des Hommes, </br>My orange"
				video={{
					id: '7de1ec495366ef6ea985e3db441772d6',
					alt: ''
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Harvest the oranges of Terre des hommes with a donation-oriented mini game.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Research',
								'Concept',
								'UX/UI',
								'Illustration',
								'Motion Design'
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							In 2020, sanitary restrictions put a strain on the famous
							oranges in the street campaign organized by Terre des Hommes.
							Turning the constraint into an opportunity, the NGO enlisted
							our help to digitalize the campaign. Objective: to remain
							visible to keep  donations coming while appealing to a younger target audience.
						</p>

						<h3>Our answer</h3>
						<p>
							A digital gamification campaign welcomes the visitor into
							a playful universe where they are encouraged  to collect
							as many oranges as possible in a short time frame. The more
							oranges they collect, the greater the impact on the field.
						</p>

						<div className="project-links text-content">
							<ButtonExternal
								href="https://myorange.tdh.ch/"
								text="Discover the site"
							/>
						</div>
					</div>
				</div>

				<Video
					className="col-full"
					src="f785b926769ea02016b6084d9364bd18"
					alt="Démo du jeu"
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_1.childImageSharp}
						alt="Plateforme média version mobile"
					/>
					<Image
						{...data.image_2.childImageSharp}
						alt="Plateforme média version mobile"
					/>
				</Gallery>

				<Column>
					<div className="column__text column__sticky text-content">
						<h3>
							Raising awareness in a light and positive tone
						</h3>
						<p>
							Once the game is over, the player can donate the total
							number of oranges collected in the basket. They can
							also customize the number of oranges, allowing them
							to choose the exact amount they wish to donate.
						</p>
						<p>
							To encourage them to make the donation, we highlight what
							concrete benefits this donation will translate to on the
							ground. All that remains is for them to take action.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_3.childImageSharp}
							alt="Symbole du sourire sur fond turquoise"
						/>
						<Video
							src="0ed7766dc11653c4bbb61326384307fe"
							alt="Remerciements pour ta contribution à l'action oranges 2021"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
